@import "/components/utils/configs/theme/colors.module.scss";

.root {
  overflow: auto !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ellipsis-text {
  text-overflow: ellipsis;
  margin: 0;
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  &::after {
    content: "...";
    position: absolute;
    right: 0;
    top: 0;
    background-color: white;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.common-input {
  border: 1px solid #cbd5e0;
  box-sizing: border-box;
  border-radius: 8px;
  height: 44px;
  width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.svgFill * {
  fill: inherit;
  stroke: inherit;
}

.svgFillAll * {
  fill: inherit;
  stroke: inherit;
}

.svgFill *[fill="white"] {
  fill: white;
}

.svgFill *[stroke="white"] {
  stroke: white;
}

// svg {
//   display: block;
// }

// ::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
// }

// ::-webkit-scrollbar-track {
//   background: $GREY_300;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 8px;
//   background: $GREY_400;
// }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bkc-account {
  background: linear-gradient(0deg, $GREY_100 50%, $GREEN_400 50%);
}

.title-sub {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 23px !important;
}

.web-kit-hidden-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.center-row {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.center-col {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-cover {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  padding: 16px 16px;
}

.arena-box-cover {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  // padding: 10px 16px
}

.card-side {
  display: flex;
  flex-direction: column;
  // background: white;
  // padding: 16px 12px;
  border-radius: 6px;
}

.grid-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
}

.circular-progress {
  // position: relative;
  // flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  float: bottom;
}
.hover-shadow:hover {
  box-shadow: 0 1px #9e9e9e;
}
.slick-slider {
  place-items: center;
  .slick-arrow {
    display: none !important;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:hover,
  :active {
    .slick-arrow {
      display: block !important;
    }
  }
}
.page-title {
  font-size: 21px;
  font-weight: 600px;
}

.box-cover-left {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  // padding-bottom: 12px;
}

.selection-quiz {
  min-width: 48px;
  height: 48px;
  padding: 0 4px;
  margin-top: 8px;
  margin-right: 20px;
  border-radius: 16px;
  text-transform: uppercase;
}

.button-result-quiz {
  justify-content: center;
  padding: 15px 8px 15px 8px !important;
  // width: 143px;
  height: 62px !important;
  background: #ffffff !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 16px !important;
  color: #212121 !important;
}

.text-title {
  font-size: 18px;
  line-height: 21.78px;
  font-weight: 600;
}

.button-relationship-friend {
  border-radius: 100px !important;
  height: 28px !important;
  padding: 6px 0px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: white !important;
  width: 149px;
}
.text-friend-no-data {
  font-size: 12px;
  color: #666666;
  font-weight: 400;
  line-height: 21.52px;
}

.text-gift-item-conditions {
  display: flex;
  font-size: 12px;
  margin-bottom: 5px;
  color: #9e9e9e;
}

.left-unset {
  left: unset !important;
}

.box-shadow-feedback {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15) !important;
}

.bg-redeem-gift {
  background: linear-gradient(180deg, #fffef0 0%, #fffae1 100%);
}

.scrollbar-thin {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border: 0;
    background-color: #e5e5e5;
  }
}
.textarea-scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border: 0;
    background-color: #e5e5e5;
  }
}