$TEAL: #00aca0;
$TEAL_400: #6dcdb8;
$TEAL_300: #4db6ac;
$TEAL_200: #80cbc4;
$TEAL_100: #b2dfdb;
$TEAL_50: #e0f2f1;

$PURPLE: #7e57c2;
$PURPLE_300: #9575cd;
$PURPLE_200: #b39ddb;
$PURPLE_100: #d1c4e9;
$PURPLE_50: #ede7f6;

$BLACK: #000000;
$BLACK_90: #313131;
$GREY: #212121;
$GREY_900: #212121;
$GREY_700: #616161;
$GREY_600: #757575;
$GREY_500: #9e9e9e;
$GREY_400: #bdbdbd;
$GREY_300: #e0e0e0;
$GREY_200: #f2f3f3;
$GREY_100: #f5f5f5;
$GREY_50: #fafafa;

$WHITE: #ffffff;
$WHITE_50: #fdfdfd;
$WHITE_10: #1a1a1a;

$BLUE_NAVY: #144da8;
$BLUE: #1b6eeb;
$BLUE_600: #47b0df;
$BLUE_500: #1976d2;
$BLUE_400: #4fc3f7;
$BLUE_300: #5a90f4;
$BLUE_250: #4794ff;
$BLUE_200: #9fbff8;
$BLUE_100: #dcebfb;
$BLUE_50: #e6f6fe;

$GREEN: #48BB78;
$GREEN_400: #18a43b;
$GREEN_300: #2FBA77;
$GREEN_200: #9ee2b8;
$GREEN_100: #cff3dd;
$GREEN_80: #e9fcee;
$GREEN_50: #f0faf3;

$RED: #f44336;
$RED_600: #ed2700;
$RED_500: #f44336;
$RED_300: #e57373;
$RED_200: #ef9a9a;
$RED_100: #ffcdd2;
$RED_50: #ffebee;

$BROWN: #653818;
$BROWN_300: #b18976;
$BROWN_200: #d2bab0;
$BROWN_100: #ebe0db;
$BROWN_50: #f6f2f0;

$ORANGE: #ed8936;
$ORANGE_300: #f19063;
$ORANGE_250: #ff7446;
$ORANGE_200: #f7bfa5;
$ORANGE_100: #fbe2d6;
$ORANGE_50: #fdf3ee;

$YELLOW: #f5a623;
$YELLOW_300: #ffcf70;
$YELLOW_200: #ffe3ac;
$YELLOW_100: #fff2d9;
$YELLOW_50: #fff9ef;

$PINK: #ff3366;
$PINK_300: #f06292;
$PINK_200: #f48fb1;
$PINK_100: #f8bbd0;
$PINK_50: #fce4ec;

$CRAYOLA: #ba68c8;

$NEUTRAL_100: #F7FAFC;
$NEUTRAL_600: #718096;
$NEUTRAL_200: #EDF2F7;
$NEUTRAL_700: #4A5568;
$NEUTRAL_900: #1A202C;
$NEUTRAL_300: #E2E8F0;

:export {
    TEAL: $TEAL;
    TEAL_400: $TEAL_400;
    TEAL_300: $TEAL_300;
    TEAL_200: $TEAL_200;
    TEAL_100: $TEAL_100;
    TEAL_50: $TEAL_50;

    PURPLE: $PURPLE;
    PURPLE_300: $PURPLE_300;
    PURPLE_200: $PURPLE_200;
    PURPLE_100: $PURPLE_100;
    PURPLE_50: $PURPLE_50;

    BLACK: $BLACK;
    BLACK_90: $BLACK_90;
    GREY: $GREY;
    GREY_900: $GREY_900;
    GREY_700: $GREY_700;
    GREY_600: $GREY_600;
    GREY_500: $GREY_500;
    GREY_400: $GREY_400;
    GREY_300: $GREY_300;
    GREY_200: $GREY_200;
    GREY_100: $GREY_100;
    GREY_50: $GREY_50;

    WHITE: $WHITE;
    WHITE_50: $WHITE_50;
    WHITE_10: $WHITE_10;

    BLUE_NAVY: $BLUE_NAVY;
    BLUE: $BLUE;
    BLUE_600: $BLUE_600;
    BLUE_500: $BLUE_500;
    BLUE_400: $BLUE_400;
    BLUE_300: $BLUE_300;
    BLUE_250: $BLUE_250;
    BLUE_200: $BLUE_200;
    BLUE_100: $BLUE_100;
    BLUE_50: $BLUE_50;

    GREEN: $GREEN;
    GREEN_400: $GREEN_400;
    GREEN_300: $GREEN_300;
    GREEN_200: $GREEN_200;
    GREEN_100: $GREEN_100;
    GREEN_80: $GREEN_80;
    GREEN_50: $GREEN_50;

    RED: $RED;
    RED_600: $RED_600;
    RED_500: $RED_500;
    RED_300: $RED_300;
    RED_200: $RED_200;
    RED_100: $RED_100;
    RED_50: $RED_50;

    BROWN: $BROWN;
    BROWN_300: $BROWN_300;
    BROWN_200: $BROWN_200;
    BROWN_100: $BROWN_100;
    BROWN_50: $BROWN_50;

    ORANGE: $ORANGE;
    ORANGE_300: $ORANGE_300;
    ORANGE_250: $ORANGE_250;
    ORANGE_200: $ORANGE_200;
    ORANGE_100: $ORANGE_100;
    ORANGE_50: $ORANGE_50;

    YELLOW: $YELLOW;
    YELLOW_300: $YELLOW_300;
    YELLOW_200: $YELLOW_200;
    YELLOW_100: $YELLOW_100;
    YELLOW_50: $YELLOW_50;

    PINK: $PINK;
    PINK_300: $PINK_300;
    PINK_200: $PINK_200;
    PINK_100: $PINK_100;
    PINK_50: $PINK_50;

    CRAYOLA: $CRAYOLA;

    NEUTRAL_600: $NEUTRAL_600;
    NEUTRAL_200: $NEUTRAL_200;
    NEUTRAL_700: $NEUTRAL_700;
    NEUTRAL_100: $NEUTRAL_100;
    NEUTRAL_900: $NEUTRAL_900;
    NEUTRAL_300: $NEUTRAL_300;
}